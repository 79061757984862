.container1 {
    padding-top: 80px;
    background-color: black;
    position: relative;
    height: 100vh;

}


.container1 img {
    position: relative;
    height: 600px;
    width: 458px;
}



.container1 .btn1 {
    position: absolute;
    width: 370px;
    height: 200px;
    left: 644px;
    top: 277px;
    border-radius: 70%;
    background-color: #FFA319;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 700;
}

.container1 .btn2 {
    position: absolute;
    width: 224px;
    height: 138px;
    left: 565px;
    top: 498px;
    background-color: #95CEFC;
    border-radius: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 700;
}

.container1 .btn3 {
    position: absolute;
    width: 222px;
    height: 138px;
    left: 891px;
    top: 498px;
    background: #D3D3D3;
    border-radius: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 700;
}

.container1 .btn4 {
    position: absolute;
    width: 216px;
    height: 138px;
    left: 1069px;
    top: 314px;
    background: #FCD2B1;
    border-radius: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 700;
}

.container1 .btn5 {
    position: absolute;
    width: 231px;
    height: 138px;
    left: 853px;
    top: 126px;

    background: #F4ABA8;
    border-radius: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 700;
}

.container1 .btn6 {

    position: absolute;
    width: 224px;
    height: 138px;
    left: 565px;
    top: 142px;
    background: #7DFFB9;

    border-radius: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 700;
}


@media (max-width: 500px) {
    .container1 {
        padding-top: 50px;
        height: 100vw;
    }

    .container1 img {
        height: 100vw;
        width: 30%;
    }

    .container1 .btn1 {
        width: 120px;
        height: 75px;
        position: absolute;
        left: 35%;
        line-height: 0;
        top: 160px;
        border-radius: 50%;
        font-size: 14px;
    }

    .container1 .btn2 {
        width: 95px;
        height: 65px;
        position: absolute;
        left: 35%;
        line-height: 0;
        top: 242px;
        border-radius: 50%;
        font-size: 12px;
    }

    .container1 .btn3 {
        width: 95px;
        height: 65px;
        position: absolute;
        left: 68%;
        line-height: 0;
        top: 242px;
        border-radius: 50%;
        font-size: 12px;
    }

    .container1 .btn4 {
        width: 75px;
        height: 50px;
        position: absolute;
        left: 75%;
        line-height: 0;
        top: 160px;
        border-radius: 50%;
        font-size: 12px;
    }

    .container1 .btn5 {
        width: 90px;
        height: 65px;
        position: absolute;
        left: 65%;
        line-height: 0;
        top: 80px;
        border-radius: 50%;
        font-size: 12px;
    }

    .container1 .btn6 {
        width: 90px;
        height: 65px;
        position: absolute;
        left: 30%;
        line-height: 0;
        top: 90px;
        border-radius: 50%;
        font-size: 12px;
    }

}

@media (min-width: 500px) and (max-width: 1024px) {
    .container1 {
        padding-top: 15vw;
        height: 50vw;
    }

    .container1 img {
        height: 45vw;
        width: 40%;
    }

    .container1 .btn1 {
        width: 30vw;
        height: 15vw;
        position: absolute;
        left: 45vw;
        line-height: 0;
        top: 20vw;
        border-radius: 50%;
        font-size: 14px;
    }

    .container1 .btn2 {
        width: 20vw;
        height: 10vw;
        position: absolute;
        left: 40vw;
        line-height: 0;
        top: 36vw;
        border-radius: 50%;
        font-size: 12px;
    }

    .container1 .btn3 {
        width: 20vw;
        height: 10vw;
        position: absolute;
        left: 70vw;
        line-height: 0;
        top: 36vw;
        border-radius: 50%;
        font-size: 12px;
    }

    .container1 .btn4 {
        width: 20vw;
        height: 10vw;
        position: absolute;
        left: 78vw;
        line-height: 0;
        top: 20vw;
        border-radius: 50%;
        font-size: 12px;
    }

    .container1 .btn5 {
        width: 20vw;
        height: 10vw;
        position: absolute;
        left: 65vw;
        line-height: 0;
        top: 10vw;
        border-radius: 50%;
        font-size: 12px;
    }

    .container1 .btn6 {
        width: 20vw;
        height: 10vw;
        position: absolute;
        left: 35vw;
        line-height: 0;
        top: 12vw;
        border-radius: 50%;
        font-size: 12px;
    }

}


.mcontainer2 {
    position: relative;
    background-image: url('../images/Company Goup (8) 1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 4%;
}

.mcontent {
    /* position: absolute; */
    display: "flex";
    justify-content: "center";
    align-items: "center";
    /* height: "100vh"; */
    display: flex;
    flex-direction: column;
    align-content: center;
    padding: 4% 0%;

}

.mcontainer2 .mcontent1 {
    box-sizing: border-box;
    position: relative;
    width: 40%;
    left: 20%;
    background: linear-gradient(0deg, rgba(255, 163, 25, 0.1), rgba(255, 163, 25, 0.1)), #FFFFFF;
    border: 4px solid rgba(255, 163, 25, 0.5);
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

.card1 {
    text-align: left;
    position: relative;
    padding: 2% 5%;
    margin: 1%;
    border-radius: 50px;
    font-size: 14px;
    z-index: 1;
}

.mcontent2 {
    box-sizing: border-box;
    position: relative;
    width: 45%;
    left: 134px;
    background: rgba(0, 0, 0, 0.1);
    border: 4px solid rgba(0, 0, 0, 0.5);
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}



.mcontent2::before {
    content: "";
    position: absolute;
    top: 0;
    right: 1%;
    width: 110%;
    height: 2px;
    background-color: #000;
    transform: rotate(-26deg);
    transform-origin: top right;
}

.mcontent2::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 1%;
    width: 110%;
    height: 2px;
    background-color: #000;
    transform: rotate(26deg);
    transform-origin: bottom right;
}

.card2 {
    text-align: left;
    position: relative;
    padding: 2%;
    margin: 1%;
    border-radius: 50px;
    font-size: 14px;
    z-index: 1;
}

.card2 ul li {
    padding: 1%;
    text-decoration: line-through;
}

.mcontainer2 .mcontentl {
    position: absolute;
    bottom: 0%;
    width: 100%;
}

.mcontainer2 .mcontentl p {
    padding: 1% 0% 0% 5%;
}

.mcontainer2 .mcontentl img {
    position: absolute;
    top: 30%;
    left: 3%;
    width: 2.4%;
    padding-right: 1%;
}

@media (max-width: 767px) {


    .mcontainer2 {
        padding-bottom: 15%;
        position: relative;
    }

    .mcontent h2 {
        font-size: 18px;
        font-weight: 600;
    }

    .mcontent {
        padding: 2% 0;
    }

    .mcontainer2 .mcontent1 {
        width: 80%;
        right: 1px;
    }

    .mcontainer2 .mcontent2 {
        width: 80%;
        left: 1px;
    }

    .mcontainer2 .mcontentl {
        position: absolute;
        margin-top: 50%;
        padding: 0%;
        text-align: center;
    }

    .mcontainer2 .mcontentl p {
        font-size: 10px;
        text-align: left;
        padding: 1%;
    }

    .mcontainer2 .mcontent2::before {
        content: "";
        position: absolute;
        top: 0;
        right: 1%;
        width: 170%;
        height: 2px;
        background-color: #000;
        transform: rotate(-54deg);
        transform-origin: top right;
    }

    .mcontainer2 .mcontent2::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 1%;
        width: 170%;
        height: 2px;
        background-color: #000;
        transform: rotate(54deg);
        transform-origin: bottom right;
    }
}

.mcontainer3 {
    position: relative;
    background-image: url('../images/unsplash_PxypFiQMkIk.png');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
}

.mcontainer3 .mcontent1 {
    padding: 4% 0%;
    display: "flex";
    justify-content: "center";
    align-items: "center";
    display: flex;
    flex-direction: column;
    align-content: center;
}

.mcontainer3 img {
    position: relative;
    width: 60%;
    height: 500px !important;
}

.mcontainer3 .mcontent-p1 {
    box-sizing: border-box;
    position: absolute;
    width: 40%;
    height: 20%;
    left: 768px;
    top: 200px;
    background: rgba(0, 0, 0, 0.1);
    border: 5px solid rgba(0, 0, 0, 0.5);
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: flex;
    align-items: center;
    text-align: center;
}

.mcontainer3 .mcontent-p2 {
    box-sizing: border-box;
    position: absolute;
    width: 30%;
    height: 20%;
    left: 893px;
    top: 375px;
    background: rgba(0, 0, 0, 0.1);
    border: 5px solid rgba(0, 0, 0, 0.5);
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: flex;
    align-items: center;
    text-align: center;
}

.mcontainer3 .mcontentl {
    position: absolute;
    bottom: 0%;
    width: 100%;
}

.mcontainer3 .mcontentl p {
    padding: 1% 1% 0% 0%;
    text-align: right;
}

.mcontainer3 .mcontentl img {
    position: absolute;
    bottom: 50%;
    right: 17.5%;
    width: 2.5%;
    height: 18% !important;
    padding-right: 1%;
}

@media (max-width: 500px) {
    .mcontainer3 .mcontent1 {
        padding: 2% 0;
    }

    .mcontent1 h2 {
        font-size: 18px;
        font-weight: 600;
    }


    .mcontainer3 img {
        width: 50%;
        height: 50% !important;
    }

    .mcontainer3 .mcontent-p1 {
        width: 70%;
        height: 28%;
        left: 30vw;
        top: 20%;
        margin-top: 25px;
        text-align: left;
        font-size: small;
        line-height: 1;
        padding: 2% 1% 0 5%;
        border-width: 2px;
    }

    .mcontainer3 .mcontent-p2 {
        width: 50%;
        height: 28%;
        left: 50%;
        top: 50%;
        margin-top: 25px;
        text-align: left;
        font-size: small;
        line-height: 1;
        padding: 3% 1% 0 3%;
        border-width: 2px;
    }

    .mcontainer3 .mcontentl {
        position: absolute;
        top: 22%;
    }

    .mcontainer3 .mcontentl p {
        padding: 1% 0 0 50%;
        text-align: center;
    }

    .mcontainer3 .mcontentl img {
        position: absolute;
        bottom: 0%;
        right: 45%;
        margin-bottom: 10px;
        width: 15px;
        height: 5px !important;
    }
}

@media (min-width: 500px)and (max-width : 1024px) {
    .mcontainer3 .mcontent1 {
        padding: 2% 0;
    }

    .mcontent1 h2 {
        font-size: 18px;
        font-weight: 600;
    }


    .mcontainer3 img {
        width: 50%;
        height: 50% !important;
    }

    .mcontainer3 .mcontent-p1 {
        width: 70%;
        left: 30vw;
        top: 20%;
        margin-top: 25px;
        text-align: left;
        font-size: small;
        line-height: 2;
        padding: 2% 1% 0 5%;
        border-width: 2px;
    }

    .mcontainer3 .mcontent-p2 {
        width: 50%;
        left: 50%;
        top: 50%;
        margin-top: 25px;
        text-align: left;
        font-size: small;
        line-height: 2;
        padding: 3% 1% 0 3%;
        border-width: 2px;
    }

    .mcontent1 {
        position: relative;
        width: 50%;
        height: 10%;
    }  

    .mcontainer3 .mcontentl p {
        text-align: right;
    }

    .mcontainer3 .mcontentl img {
        position: absolute;
        right: 25%;
        
    }
}


.mcontainer4 {
    position: relative;
    width: 100%;
    height: 80vh;
    background: #000000;
    display: flex;
    align-items: center;
}

.mcontainer4 .mcontent {
    width: 50%;
    color: #FFA319;
    padding-left: 8%;
    line-height: 2.5;
}

.mcontainer4 .mcontent img {
    position: relative;
    width: 94%;
    height: 80%;
    left: 6%;
}

.mcontainer4 .mcontentl {
    position: absolute;
    bottom: 0%;
    width: 100%;
    color: white;
}

.mcontainer4 .mcontentl p {
    padding: 1% 0% 0% 5%;
}

.mcontainer4 .mcontentl img {
    position: absolute;
    top: 30%;
    left: 3%;
    width: 2.5%;
    height: 20% !important;
    padding-right: 1%;
}

@media (max-width: 767px) {
    .mcontainer4 {
        height: auto;
    }

    .mcontainer4 .mcontent {
        width: 100%;
        padding: 20% 0% 10% 5%;
        line-height: 1;
        text-align: left;
    }

    .mcontainer4 .mcontent img {
        width: 100%;
        left: 0.2%;
    }

    .mcontainer4 .mcontentl img {
        position: absolute;
        top: 20%;
        left: 0%;
        width: 20px;
        height: auto;
    }
}



.mcontainer5 {
    /* width: 100%; */
    height: 680px;
    background-image: url('../images/Untitled\ design\ \(13\)\ 1\ \(1\).png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.mcontainer5 .mcontent {
    color: #FFA319;
    padding-top: 4%;
}


.mcontainer5 .mcontentl {
    position: relative;
    top: 30%;
    width: 100%;
}

.mcontainer5 .mcontentl p {
    padding: 1% 1% 0% 0%;
    text-align: right;
    color: white;
}

.mcontainer5 .mcontentl img {
    position: absolute;
    top: 50%;
    right: 25%;
    width: 2.5%;
    height: 20% !important;
    padding-right: 1%;
}


@media (max-width: 767px) {
    .mcontainer5 {
        height: 90vh;
        background-size: cover;
        position: relative;
    }

    .mcontainer5 .mcontent {
        padding-top: 10%;
        line-height: 1.5;
    }

    .mcontainer5 .mcontent {
        width: 100%;
        padding: 20% 0% 10% 5%;
        line-height: 1;
        text-align: left;
    }

    .mcontainer5 .mcontent img {
        width: 100%;
        left: 0.2%;
    }

    .mcontainer5 .mcontentl {
        position: relative;
        top: 20%

    }

    .mcontainer5 .mcontentl img {
        position: absolute;
        top: 0%;
        left: 0%;
        width: 20px;
        height: auto;
    }
}

@media (min-width: 767px)and(max-width: 1250px) {
    .mcontainer5 {
        height: 70vh !important;
        background-size: cover;
        position: relative;
    }

    .mcontainer5 .mcontent {
        padding-top: 10%;
        line-height: 1.5;
    }

    .mcontainer5 .mcontent {
        width: 100%;
        padding: 20% 0% 10% 5%;
        line-height: 1;
        text-align: left;
    }


    .mcontainer5 .mcontentl img {
        position: absolute;
        height: auto;
    }

}

.mcontainer6 {
    height: 95vh;
    background-image: url('../images/unsplash_PxypFiQMkIk.png');
}

.mcontainer6 .mcontent {
    color: #000;
    padding: 4%;
}

.mcontainer6 .card {
    text-align: left;
    position: relative;
    width: 40%;
    padding: 2%;
    margin-left: 5%;
    border-radius: 50px;
    font-size: 14px;
    display: flex;
    color: #FFA319;
    background-color: #000000;
}

.mcontainer6 .card .card1 {
    padding: 2%;
}

.mcontainer6 .card .card2 {
    display: flex;
    justify-content: space-around;
}

.mcontainer6 .card .card2 .card-1 ul li {
    padding: 1%;
    text-decoration: none;
}

.card-2 ul li {
    padding: 1%;
    text-decoration: none;
}



.mcontainer6 .imgp-1 img {
    box-sizing: border-box;
    position: absolute;
    width: 15%;
    height: 38%;
    left: 55%;
    top: 570%;
    z-index: 1;

}

.mcontainer6 .imgp-2 img {
    box-sizing: border-box;
    position: absolute;
    width: 15%;
    height: 20%;
    left: 65%;
    top: 555%;
}

.mcontainer6 .mcontentl {
    padding: 5% 12% 0% 12%;
}

@media (max-width: 767px) {
    .mcontainer6 {
      height: auto;
      min-height: 100vh;
      position: relative;
    }

    .mcontent img{
        width: 15%;
    }
  
    .mcontainer6 .card {
      width: 90%;
    }
  
    .mcontainer6 .imgp-1 img{
        position: absolute;
        width: 45%;
        height: 18%;
        top :70%;
        left: 20%;

    }
    .mcontainer6 .imgp-2 img {
        position: absolute;
        width: 50%;
        height: 15%;
        top :60%;
        left: 45%;
    }
  
    .mcontainer6 .mcontentl {
      padding: 5% 8% 0% 8%;
    }
  }
  