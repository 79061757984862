body {
    background-color: #E5E5E5 !important;
}

.ccontainer1 {
    padding-top: 10%;
    background-color: black;
    position: relative;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.ccontainer1 .content {
    position: absolute;
    padding-left: 8%;
}

.ccontainer1 .content h1 {
    color: #FFA319;
    padding: 0% 0% 3% 2%;
}

.ccontainer1 .content p {
    color: #FFFFFF;
    padding: 2%;
    width: 50%;
}

.ccontainer1 img {
    width: 50%;
    height: 67.5vh;
    position: relative;
    left: 50%;
    top: 11.75vh;
}

@media (max-width: 768px) {
    .ccontainer1 {
        height: 100vh;
        display: flex;
    }

    .ccontainer1 .content {
        padding: 0%;
        width: 100%;
        text-align: center;
    }

    .ccontainer1 .content p {
        padding-left: 2%;
        width: 100%;
    }


    .ccontainer1 img {
        position: relative;
        left: 0%;
        top: 148.5%;
        width: 100%;
        height: 38vh;
    }
}

.ccontainer2 {
    height: 50vh;
}

.ccontainer2 .card {
    position: relative;
    left: 10%;
    width: 43%;
    height: 40vh;
    font-size: 14px;
    background-color: rgba(255, 163, 25, 0.1);
    display: flex;
    margin: 8% 3% 0% 3%;
    padding: 2%;
    border: 4px solid rgba(255, 163, 25, 0.5);
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.ccontainer2 .card h3 {
    padding: 1.5%;
}

.ccontainer2 .card h5 {
    padding: 1%;
    font-style: italic;
}

.ccontainer2 .card p {
    padding: 1%;
}

.ccontainer2 .card ul li {
    padding: 1.5%;
}

.ccontainer2 img {
    position: relative;
    width: 18%;
    left: 46%;
    bottom: 39vh;
}

@media (max-width: 768px) {
    .ccontainer2 {
        height: 40vh;
    }

    .ccontainer2 .card {
        position: relative;
        left: 10%;
        width: 55%;
        height: 30vh;
        padding: 2%;
        line-height: 1;
    }

    .ccontainer2 .card h3 {
        padding: 1.5%;
        font-size: 14px;
    }

    .ccontainer2 .card h5 {
        padding: 1%;
        font-size: 14px;
    }

    .ccontainer2 .card p {
        padding: 1%;
        font-size: 14px;
        line-height: 1.5;
        width: 100%;
    }

    .ccontainer2 .card ul li {
        padding: 1%;
        font-size: 14px;
        width: 100%;
    }

    .ccontainer2 img {
        position: relative;
        width: 20%;
        left: 58%;
        bottom: 20vh;
    }
}

.ccontainer3 {
    height: 50vh;
}

.ccontainer3 .card {
    position: relative;
    left: 45%;
    width: 43%;
    height: 40vh;
    font-size: 14px;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    padding: 2% 2% 2% 8%;
    border: 4px solid rgba(0, 0, 0, 0.5);
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.ccontainer3 .card h3 {
    padding: 2%;
}

.ccontainer3 .card h5 {
    padding: 2%;
    font-style: italic;
}

.ccontainer3 .card p {
    width: 80%;
    padding: 2%;
}


.ccontainer3 img {
    position: relative;
    width: 15%;
    left: 35%;
    bottom: 36vh;
}

@media (max-width: 768px) {
    .ccontainer3 {
        height: 40vh;
    }

    .ccontainer3 .card {
        position: relative;
        left: 35%;
        width: 55%;
        height: 30vh;
        padding: 2%;
        line-height: 1;

    }

    .ccontainer3 .card h3 {
        padding: 1.5%;
        font-size: 14px;
        text-align: center;
    }

    .ccontainer3 .card h5 {
        padding: 1%;
        font-size: 14px;
        text-align: center;
    }

    .ccontainer3 .card p {
        padding: 1%;
        font-size: 14px;
        line-height: 1.5;
        width: 100%;
    }

    .ccontainer3 img {
        position: relative;
        width: 20%;
        left: 18%;
        bottom: 20vh;
    }
}

.ccontainer4 {
    height: 50vh;
}

.ccontainer4 .card {
    position: relative;
    left: 10%;
    width: 43%;
    height: 40vh;
    font-size: 14px;
    background-color: rgba(255, 163, 25, 0.1);
    display: flex;
    margin: 6% 3% 0% 3%;
    padding: 2% 6% 2% 0%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 4px solid rgba(255, 163, 25, 0.5);
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.ccontainer4 .card h3 {
    padding: 1.5%;
}

.ccontainer4 .card h5 {
    padding: 1%;
    font-style: italic;
    font-size: 16px;
}


.ccontainer4 img {
    position: relative;
    width: 15%;
    left: 50.5%;
    bottom: 35vh;
}

@media (max-width: 768px) {
    .ccontainer4 {
        height: 40vh;
    }

    .ccontainer4 .card {
        position: relative;
        left: 7%;
        width: 55%;
        height: 25vh;
        padding: 2%;
        line-height: 1;
    }

    .ccontainer4 .card h3 {
        padding: 1.5%;
        font-size: 14px;
    }

    .ccontainer4 .card h5 {
        padding: 1%;
        font-size: 14px;
    }

    .ccontainer4 .card p {
        padding: 1%;
        font-size: 14px;
        width: 45%;
    }


    .ccontainer4 img {
        position: relative;
        width: 20%;
        left: 60%;
        bottom: 18vh;
    }
}

.ccontainer5 {
    height: 50vh;
}

.ccontainer5 .card {
    position: relative;
    left: 45%;
    width: 43%;
    height: 40vh;
    font-size: 14px;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 2% 2% 2% 10%;
    display: flex;
    align-items: left;
    justify-content: center;
    border: 4px solid rgba(0, 0, 0, 0.5);
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

}

.ccontainer5 .card h3 {
    padding: 2%;
}

.ccontainer5 .card h5 {
    padding: 2%;
    font-style: italic;
}

.ccontainer5 .card p {
    width: 80%;
    padding: 2%;
}


.ccontainer5 img {
    position: relative;
    width: 25%;
    left: 32.5%;
    bottom: 40vh;
}

@media (max-width: 768px) {
    .ccontainer5 {
        height: 40vh;
    }

    .ccontainer5 .card {
        position: relative;
        left: 35%;
        width: 55%;
        height: 30vh;
        padding: 2%;
        line-height: 1;

    }

    .ccontainer5 .card h3 {
        padding: 1.5%;
        font-size: 14px;
        text-align: center;
    }

    .ccontainer5 .card h5 {
        padding: 1%;
        font-size: 14px;
        text-align: center;
    }

    .ccontainer5 .card p {
        padding: 1% 1% 1% 8%;
        font-size: 14px;
        line-height: 1.5;
        width: 100%;
    }

    .ccontainer5 img {
        position: relative;
        width: 20%;
        left: 25%;
        bottom: 20vh;
    }
}

.ccontainer6 {
    height: 50vh;
}

.ccontainer6 .card {
    position: relative;
    left: 10%;
    width: 43%;
    height: 40vh;
    font-size: 14px;
    background-color: rgba(255, 163, 25, 0.1);
    display: flex;
    margin: 3% 3% 0% 3%;
    padding: 2% 6% 2% 4%;
    display: flex;
    align-items: left;
    justify-content: center;
    border: 4px solid rgba(255, 163, 25, 0.5);
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}


.ccontainer6 .card h3 {
    padding: 2%;
}

.ccontainer6 .card h5 {
    padding: 2%;
    font-style: italic;
}

.ccontainer6 .card p {
    width: 80%;
    padding: 2%;
}

.ccontainer6 img {
    position: relative;
    width: 15%;
    left: 49.5%;
    bottom: 34vh;
}

@media (max-width: 768px) {
    .ccontainer6 {
        height: 40vh;
    }

    .ccontainer6 .card {
        position: relative;
        left: 7%;
        width: 60%;
        height: 28vh;
        padding: 2%;
        line-height: 1;
    }

    .ccontainer6 .card h3 {
        padding: 1.5%;
        font-size: 14px;
    }

    .ccontainer6 .card h5 {
        padding: 1%;
        font-size: 14px;
    }

    .ccontainer6 .card p {
        padding: 1%;
        font-size: 14px;
    }


    .ccontainer6 img {
        position: relative;
        width: 20%;
        left: 60%;
        bottom: 18vh;
    }
}

.ccontainer7 {
    height: 50vh;
}

.ccontainer7 .card {
    position: relative;
    left: 45%;
    width: 43%;
    height: 40vh;
    font-size: 14px;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 2% 2% 2% 10%;
    display: flex;
    align-items: left;
    justify-content: center;
    border: 4px solid rgba(0, 0, 0, 0.5);
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.ccontainer7 .card h3 {
    padding: 5% 2%;
}


.ccontainer7 .card p {
    width: 80%;
    padding: 0% 2%;
    line-height: 1.6;
}


.ccontainer7 img {
    position: relative;
    width: 20%;
    left: 35.5%;
    bottom: 40vh;
}

@media (max-width: 768px) {
    .ccontainer7 {
        height: 40vh;
    }

    .ccontainer7 .card {
        position: relative;
        left: 25%;
        width: 75%;
        height: 35vh;
        padding: 2%;
        line-height: 1;

    }

    .ccontainer7 .card h3 {
        padding-top: 10%;
        font-size: 14px;
        text-align: center;
    }


    .ccontainer7 .card p {
        padding: 0% 1% 1% 8%;
        font-size: 14px;
        line-height: 1.25;
        width: 100%;
    }

    .ccontainer7 img {
        position: relative;
        width: 20%;
        left: 15%;
        bottom: 20vh;
    }
}

.ccontainer8 {
    height: 50vh;
}

.ccontainer8 .card {
    position: relative;
    left: 10%;
    width: 43%;
    height: 40vh;
    font-size: 14px;
    background-color: rgba(255, 163, 25, 0.1);
    display: flex;
    margin: 3% 3% 0% 3%;
    padding: 2% 6% 2% 4%;
    display: flex;
    align-items: left;
    justify-content: center;
    border: 4px solid rgba(255, 163, 25, 0.5);
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}


.ccontainer8 .card h3 {
    padding: 2%;
}



.ccontainer8 .card p {
    width: 80%;
    padding: 1% 2%;
    line-height: 1.8;
}

.ccontainer8 img {
    position: relative;
    width: 16.5%;
    left: 47.5%;
    bottom: 40vh;
}

@media (max-width: 768px) {
    .ccontainer8 {
        height: 40vh;
    }

    .ccontainer8 .card {
        position: relative;
        left: 7%;
        width: 75%;
        height: 35vh;
        padding: 2%;
        line-height: 1;
    }

    .ccontainer8 .card h3 {
        padding-top: 1.5%;
        font-size: 14px;
    }


    .ccontainer8 .card p {
        padding: 1%;
        font-size: 14px;
        width: 100%;
    }


    .ccontainer8 img {
        position: relative;
        width: 30%;
        left: 72%;
        bottom: 21vh;
    }
}

.ccontainer9 {
    height: 50vh;
}

.ccontainer9 .card {
    position: relative;
    left: 45%;
    width: 43%;
    height: 40vh;
    font-size: 14px;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 2% 2% 2% 10%;
    display: flex;
    align-items: left;
    justify-content: center;
    border: 4px solid rgba(0, 0, 0, 0.5);
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.ccontainer9 .card h3 {
    padding: 2%;
}


.ccontainer9 .card p {
    width: 80%;
    padding: 1% 2%;
    line-height: 2;
}


.ccontainer9 img {
    position: relative;
    width: 15%;
    left: 37.5%;
    bottom: 35vh;
}

@media (max-width: 768px) {
    .ccontainer9 {
        height: 40vh;
    }

    .ccontainer9 .card {
        position: relative;
        left: 25%;
        width: 75%;
        height: 35vh;
        padding: 2%;
        line-height: 1;

    }

    .ccontainer9 .card h3 {
        padding-top: 10%;
        font-size: 14px;
        text-align: center;
    }


    .ccontainer9 .card p {
        padding: 0% 1% 1% 8%;
        font-size: 14px;
        line-height: 1.25;
        width: 100%;
    }

    .ccontainer9 img {
        position: relative;
        width: 20%;
        left: 15%;
        bottom: 20vh;
    }
}