.gcontainer1 {
    background-image: url('../images/unsplash_PxypFiQMkIk.png') !important;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding: 10vh 3vh;
}

.gcontainer1 h3 {
    padding: 3% 0% 1% 10%;
}

.gcontainer1 .gcontent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    grid-row-gap: 8%;
    padding: 1% 4% 4% 4%;
}


.gcontainer1 .gcontent h4 {
    font-style: italic;
}

.gcontainer1 .gcard {
    background-color: black;
    color: #FFA319;
    width: 75%;
    border-radius: 8%;
    line-height: 1.8;
    padding: 4% 3%;
}

.gcontainer1 .gcard ul {
    padding: 3% 0% 0% 15%;

}

.gcontainer1 .gcard p {
    text-align: center;
    margin: 5% 0%;
}

@media (max-width: 767px) {
    .gcontainer1 {
      height: 500%;
      padding: 5vh 3vh 40vh 3vh;
    }
  
    .gcontainer1 h3 {
      padding: 0 5% 2% 5%;
      text-align: center;
    }
  
    .gcontainer1 .gcontent {
      grid-template-columns: 1fr;
      justify-items: center;
      padding: 2% 4% 4% 4%;
    }
  
    .gcontainer1 .gcard {
      width: 100%;
      height: auto;
      border-radius: 8px;
      padding: 4% 3%;
    }
  
    .gcontainer1 .gcard ul {
      padding-left: 10%;
    }
  }

.gcontainer2 {
    position: relative;
    padding-top: 5%;
    height: 95vh;
    background-color: #E5E5E5;
    padding: 5% 10%;
}

.gcontainer2 .gcard {
    height: 73vh;
    width: 38%;
    border: 2px solid #000000;
    border-radius: 20px;
    padding: 2%;
    line-height: 1.5;
}

.gcontainer2 .gcard div {
    padding: 4%;
}

.gcontainer2 .img1 img {
    width: 32%;
    position: relative;
    left: 52%;
    bottom: 64vh;
    z-index: 1;
}

.gcontainer2 .img2 img {
    position: relative;
    width: 30%;
    height: 35vh;
    left: 62%;
    bottom: 78vh;
}

@media (max-width: 767px) {
    .gcontainer2 {
      height: auto;
      padding: 8% 5%;
    }
  
    .gcontainer2 .gcard {
      width: 100%;
      height: auto;
      border-radius: 10px;
      padding: 4% 3%;
      margin-bottom: 5%;
    }
  
    .gcontainer2 .gcard div {
      padding: 4% 0;
    }

    .gcontainer2 .img1 img {
    width: 40%;
    position: relative;
    left: 18%;
    bottom: -2vh;
    z-index: 1;
}

.gcontainer2 .img2 img {
    position: relative;
    width: 40%;
    height: 15vh;
    left: 30%;
    bottom: 3vh;
}

  }
  
.gcontainer3 {
    height: 98vh;
    background-image: url('../images/unsplash_PxypFiQMkIk.png') !important;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 3%;
    width: 100%;
    height: fit-content;
}

.gcontainer3 .gcontent {
    padding: 1% 10%;
}

.gcontainer3 .gcontent ul li{
    padding: 0.5%;    
}

.gcontainer3 .gcontent ul .links a{
    font-weight: 500;
    line-height: 2;
    padding: 2% 0% 0% 10%;
    font-style: italic;
    color: #000000 !important;
}

.gcontainer3 .gcontent ul ol {
    list-style-type: none;
    counter-reset: my-counter;
  }
  
  .gcontainer3 .gcontent ul  ol li {
    position: relative;
    padding-left: 2%;
  }
  
  .gcontainer3 .gcontent ul  ol li::before {
    content: counter(my-counter) ")";
    counter-increment: my-counter;
    padding-right: 2%;
  }
  
  @media (max-width: 767px) {
    .gcontainer3 {
      height: auto;
      padding: 8% 5%;
    }
  
    .gcontainer3 .gcontent {
      padding: 2% 0%;
    }
  
    .gcontainer3 .gcontent ul li {
      padding: 2% 0;
    }
  
    .gcontainer3 .gcontent ul .links a {
      padding-left: 0;
    }
  
    .gcontainer3 .gcontent ul ol li {
      padding-left: 0%;
    }
  }