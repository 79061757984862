.Acontainer1 {
    padding-top: 10%;
    background-color: black;
    position: relative;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.Acontainer1 .content {
    position: absolute;
    width: 70%;
    padding-left: 8%;


}

.Acontainer1 .content h1 {
    color: #FFA319;
    margin: 1% 0%;
}

.Acontainer1 .content p {
    color: #FFFFFF;
    margin: 5% 0%;
    width: 80%;
}

.Acontainer1 .content hr {
    border: 1px solid #FFFFFF;
    width: 50%;
    margin: 5% 0% 7%;
}

.Acontainer1 .img1 img {
    width: 25%;
    position: relative;
    left: 60%;
    top: 10vh;
}

.Acontainer1 .img2 img {
    position: relative;
    width: 13%;
    height : 35vh ;
    left: 76%;
    bottom: 2.5vh;
}

@media (max-width: 768px) {
    .Acontainer1 {
      height: 100vh;
    }
  
    .Acontainer1 .content {
      width: 70%;
    }

    .Acontainer1 .img1 img {
        width: 30%;
        height: 15%;
        position: absolute;
        left: 60%;
        top: 40vh;
    }    
    .Acontainer1 .img2 img {
        position: relative;
        width: 17%;
        height : 15vh ;
        left: 76%;
        top : 305%;
    }
  }

.Acontainer2{
    position: relative;
    padding-top: 9%;
    height:100vh;
    background-image: url('../images/unsplash_PxypFiQMkIk.png');
}
.Acontainer2 .card {
    position: relative;
    left: 25%;
    width: 45%;
    border-radius: 50px;
    font-size: 14px;
    color: #FFA319;
    background-color: #000000;
    display: flex;
    align-items: center;
    padding: 2% 3%;    
}

.Acontainer2 .card h3{
    padding: 2%;
}

.Acontainer2 .card ul li{
    padding: 2%;
}

.Acontainer2 .img1 img{

    position: relative;
    height : 28vh;
    width: 20;
    left: 65%;
    bottom: 7vh;
}

.Acontainer2 .img2 img{
    position: relative;
    height : 28vh;
    width: 20;
    left: 20%;
    bottom: 95vh;
}

@media (max-width: 768px) {
    .Acontainer2 {
      height: 95vh;
      padding: 10% 0%;
    }

    .Acontainer2 .card{
        position: relative;
        left: 15%;
        top: 20%;
        width: 70%;
        height: 65%;
        display: flex;
        justify-content: center;
    }

    .Acontainer2 .img1 img{
        position: relative;
        width: 35%;
        height: 45%;
        left: 60%;
        top: 7vh;
    }

    .Acontainer2 .img2 img{
        position: relative;
        width: 35%;
        height: 30%;
        left: 6%;
        bottom: 85vh;
    }
  }



.Acontainer3{
    position: relative;
    padding-top: 5%;
    height: 95vh;
    background-color: #E5E5E5;
}
.Acontainer3 .card {
    position: relative;
    left: 18%;
    width: 45%;
    border-radius: 50px;
    font-size: 14px;
    color: #FFA319;
    background-color: #000000;
    display: flex;
    align-items: center;
    padding: 2% 0;    
}

.Acontainer3 .card h3{
    text-align: left;
    padding: 2% ;
}

.Acontainer3 .card ul li{
    padding: 2%;
}

.Acontainer3 img{
    position: relative;
    height : 28vh;
    width: 20;
    left: 53%;
    bottom: 8vh;
}


@media (max-width: 768px) {
    .Acontainer3 {
      height: 70vh;
    }

    .Acontainer3 .card{
        position: relative;
        margin-top: 10%;
        left: 25%;
        width: 70%;
        height: 60%;
        display: flex;
        justify-content: center;
    }

    .Acontainer3 img{
        position: relative;
        width: 30%;
        height: 50%;
        left: 10%;
        bottom: 5vh;
    }
  }