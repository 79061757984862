 
.hcontainer{
    background-image: url('../images/unsplash_PxypFiQMkIk.png') !important;
    width: 100%;
    height: 100vh;
    padding: 15vh 3vh 0 10vh;
    line-height: 2;
}

.hcontainer h2{
    font-weight: 600;
    padding: 1%;
}

.hcontainer h5{
    padding: 1%;
    font-weight: 600;
    font-style: italic;
}

.hcontainer p{
    padding-left: 4%;
}

.instructions-list {
    margin-bottom: 1px; /* Adjust the value as needed */
  }