/* NoOfRoundsTable.css */

.NoOfRoundsTable {
	width: 40%;
	border-collapse: collapse;
}

.NoOfRoundsTable,
.NoOfRoundsTable th,
.NoOfRoundsTable td {
	border: 2px solid black;
}

.NoOfRoundsTable th,
.NoOfRoundsTable td {
	padding: 10px;
	text-align: center;
}

.NoOfRoundsTable th {
	background-color: black; /* Change background color to black */
	color: white; /* Change text color to white */
	text-align: center;
}
.NoOfRoundsColumn {
	width: 100px; /* Adjust as needed */
}

.NoteColumn {
	width: 190px; /* Adjust as needed */
}

.tblcenterAligned td {
	text-align: center;
}

.tblgrayBackground {
	background-color: #a09c9c; /* This is a light gray color, adjust the hex value as desired */
}


/* New combined class */
.tblCenterAlignedBlueBackground {
	background-color: #9ba4f1; /* Blue background */
	text-align: center; /* Center text alignment */
  }

.scheduleTable {
	width: 45%; /* Or whatever width you prefer */
	border-collapse: collapse;
}

.scheduleTable,
.scheduleTable th,
.scheduleTable td {
	border: 2px solid black;
}

.scheduleTable th,
.scheduleTable td {
	padding: 10px;
	text-align: center;
}

.scheduleTable th {
	background-color: black; /* Change background color to black */
	color: white; /* Change text color to white */
	text-align: center;
}

#howToGetThere {
	scroll-margin-top: 100px; 
}

#ZeroStart {
	scroll-margin-top: 80px; 
}

#duration {
	scroll-margin-top: 80px; 
}

#payments {
	scroll-margin-top: 80px; 
}

@media (max-width: 500px) {
	.scheduleTable {
		width: 100%; /* Or whatever width you prefer */
	}
	.categoriesTable {
		width: 100%;
	}
	.NoOfRoundsTable
    {
		width: 100%;
	}

}
