body {
    background-image: url('../images/unsplash_PxypFiQMkIk.png') !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

.tcontainer {
    padding: 10% 5%;
}

.dropdown {
    position: relative;
    padding: 2% 0% 0% 2%;
    display: flex;
    width: 90%;
}

.dropdown p {
    font-style: italic;
    font-weight: 500;
}

.dropdown button {
    background-image: url('../images/unsplash_PxypFiQMkIk.png') !important;
    border: none;
    position: absolute;
    right: 0;
}

.dropdown .content  {
    /* position: relative;
    top: 0%;
    right : 10%; */
    display: flex;
    flex-direction: column;
    align-items: left;
    /* text-align: left;  */
    padding-top: 7%;
    line-height: 2;
}

.tcontainer hr{
    border: 1.5px solid #000000;
}