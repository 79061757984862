.content-hybrid {
	text-align: center;
}
.hybrid-container {
	margin-top: 10px;
	padding-top: 10px;
}
.btn-demo-hybrid {
	background-color: #ffa319;
	color: black;
	border-radius: 10px;
	margin-bottom: 15px;
	padding: 10px;
}
