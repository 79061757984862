.container-annual {
	background-color: black;
	padding: 20px;
	margin-top: 20px;
}
.container-annual h1 {
	text-align: center;
	padding-top: 30px;
	margin-top: 20px;
	color: white;
	font-size: 45px;
}
.container-annual h3 {
	color: #007bff;
}
.container-annual h4 {
	color: #ffa319;
	font-size: 20px;
}
.container-annual p {
	color: white;
}
.container-annual span {
	color: #f4f2ef;
}
.orange-text {
	color: #ffa319;
}

/* Keynote Presentations Section Indentation */
.event-highlights {
	text-align: left;
	margin-top: 40px;
	color: #ffa319;
	padding-left: 20px;
	margin-bottom: 0px; /* Reduced bottom margin to bring "Keynote" closer */
}

.indent-section {
	padding-left: 60px;
	margin-top: 10px; /* Reduced top margin to bring "Keynote" closer to "Event Highlights" */
}

.indent-innovations,
.indent-future,
.indent-Evaluating {
	display: block;
	margin-top: 10px;
	margin-bottom: 5px;
	cursor: pointer;
	color: #007bff;
	padding-left: 40px;
}

.indent-details {
	padding-left: 60px;
	color: orange; /* Changed to orange for visibility and consistency with your request */
	margin-top: 5px;
	margin-bottom: 20px;
}

.indent-span {
	padding-left: 50px;
}

.indent-section h4 {
	color: white;
}

.indent-section h5 {
	color: white;
	font-size: 'smaller';
}

.blue-bullet {
	color: blue;
}

.kvraoimage-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 10px;
    padding-left: 50px; /* Adjust padding as necessary */
    margin-bottom: 20px;
    margin-top: 20px;
}

.kvraoimage-container img {
    max-width: 100%; /* Ensures image doesn't exceed the container's width */
    height: auto; /* Maintains the aspect ratio */
}

/* Adding this media query to control image size on larger screens */
@media (min-width: 1024px) {
    .kvraoimage-container img {
        max-width: 25%; /* Adjust this value to manage image size on desktop */
        /* You might also want to center the image if it's becoming smaller than the container */
        /* margin: 0 auto;  This centers the image in the container */
    }
}

@media screen and (max-width: 600px) {
	#map {
		width: 100%;
		height: 300px; /* Adjust the height as needed */
	}
	.container-annual h1 {
		font-size: 32px;
	}
	.indent-section {
		padding: 0px;
	}
	.indent-span {
		padding: 0px;
	}
	.container-annual {
		padding: 5px;
		margin: 0px;
	}
}
