body {
	font-family: 'Open Sans', sans-serif;
}

.row-f {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.row-f-1 {
	padding: 1vh;
	max-height: fit-content;
}

.row-f-i {
	display: flex;
	justify-content: space-around;
	align-items: center;
	background-color: #000000;
}

.nav-foot {
	display: flex;
}

.img-thumbnail {
	background-color: black !important;
	border: #000000 !important;
	padding: 0px 3px !important;
	margin: 0px 0px 0px !important;
}

.foot {
	padding: 2vh;
}

@media (min-width: 500px) {
	.nav {
		display: flex;
	}

	.nav-item {
		padding: 0px 60px 0px 60px;
	}

	.nav-link {
		color: #ffffff !important;
	}
}

.menuIcon {
	width: 100vw;
	display: flex;
	padding: 2px 10px;
	justify-content: space-between;
	background-color: #000000;
}

.ant-drawer-header {
	flex-direction: column !important;
	align-items: flex-end !important;
}

.ant-menu-dark {
	color: white;
	text-decoration: none !important;
}

.ant-menu-item :hover {
	color: #ffa319;
}

.ant-menu-item ::selection {
	color: #ffa319;
}

.ant-menu-title-content a {
	text-decoration: none !important;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item-selected {
	background-color: #000000 !important;
	color: #ffa319 !important;
}

.ant-menu-dark > .ant-menu.ant-menu-horizontal > .ant-menu-submenu-selected {
	color: #ffa319 !important;
	background-color: black !important;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item::after {
	color: #ffa319;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu-selected {
	color: #ffa319;
	background-color: #000000 !important;
}

/* home page */

.containerh {
	position: relative;
	width: 100%;
	padding: 5vw 0vw 5vw 3vw;
	text-align: left;
	background-color: black;
	color: #ffffff;
}

.row {
	width: 100%;
}

.slider {
	/* height: 100px; */
	width: 100%;
	background-color: black;
	color: #ffffff;
	text-align: left;
	position: relative;
	padding-bottom: 10px;
}

.caption {
	line-height: 100px;
	font-size: 60px;
	color: #ffa319;
	position: relative;
	top: 10%;
	transform: translateY(-50%);
}

.caption h1 {
	font-size: 54px;
}

.p {
	font-size: 30px !important;
}

@media (max-width: 500px) {
	.containerh {
		padding-top: 10%;
		width: 100%;
		height: 115vh;
	}

	.row {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.caption h1 {
		font-size: 28px;
	}

	.p {
		font-size: 22px !important;
	}

	.content-top {
		animation: slideInLeft 1s ease-in-out;
		padding: 0;
		width: 100%;
		text-align: center;
	}

	.content-top-r {
		animation: slideInRight 1s ease-in-out;
		padding: 0;
		width: 100%;
	}

	.home-p {
		text-align: center;
		position: relative;
		font-size: 20px !important;
	}

	.btn-demo {
		margin: 2vw 0vw 0vw 0vw;
	}

	.btn-ctn {
		margin: 2vw 0vw 0vw 0vw;
	}

	.homeImage-top {
		display: none;
	}
}

@media (min-width: 500px) and (max-width: 1024px) {
	.containerh {
		padding-top: 10%;
		width: 100%;
		height: 55vh;
	}

	.row {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.caption h1 {
		font-size: 28px;
	}

	.p {
		font-size: 22px !important;
	}

	.content-top {
		animation: slideInLeft 1s ease-in-out;
		padding: 0;
		width: 100%;
		text-align: center;
	}

	.content-top-r {
		animation: slideInRight 1s ease-in-out;
		padding: 0;
		width: 100%;
	}

	.home-p {
		text-align: center;
		position: relative;
		font-size: 20px !important;
	}

	.btn-demo {
		margin: 2vw 0vw 0vw 0vw;
	}

	.btn-ctn {
		margin: 2vw 0vw 0vw 0vw;
	}

	.homeImage-top {
		display: none;
	}
}

.homeImage-top {
	position: relative;
	width: 87.5%;
	height: 90%;
	left: 14vh;
	top: 0vh;
}

@keyframes slideInLeft {
	from {
		transform: translateX(-100%);
	}

	to {
		transform: translateX(0);
	}
}

@keyframes slideInRight {
	from {
		transform: translateX(100%);
	}

	to {
		transform: translateX(0);
	}
}

.content-top {
	animation: slideInLeft 1s ease-in-out;
}

.content-top-r {
	animation: slideInRight 1s ease-in-out;
}

.content-top {
	padding: 8vw 0vw 0vw 8vw;
	width: 80%;
}

.home-p {
	text-align: left;
	position: relative;
	font-size: 22px;
	top: 6vh;
}

.home-pl a {
	color: #ffa319;
	padding-left: 5vh;
	text-decoration: none !important;
}

.btn-demo {
	margin: 2vw 2vw 0vw 0vw;
	background-color: #ffa319;
	color: black;
	border-radius: 10px;
	padding: 0.5vw 0.5vw 0.5vw 0.5vw;
}

.btn-ctn {
	margin: 2vw 2vw 0vw 5vw;
	padding: 0.5vw 0.5vw 0.5vw 0.5vw;
	background-color: black;
	color: #ffa319;
	border: 1px solid #ffa319 !important;
	border-radius: 10px;
}

.container2 {
	background-image: url('./images/Rectangle\ 70\ \(1\).png') !important;
	width: 100%;
	text-align: center;
	padding: 5vh;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.container2-content {
	margin: 40px 10px;
	width: 80%;
	height: 65vh;
	background-color: black;
	display: flex;
	align-items: center;
	text-align: left;
	border-radius: 100px;
	color: #ffa319;
	line-height: 2;
}

.container2-cards {
	padding-left: 5%;
	font-size: large;
}

.bold {
	font-weight: 720;
}

.container2-content img {
	position: relative;
	left: -2%;
	top: 2%;
	width: 40%;
	height: 100%;
	border-radius: 100px;
}

.b-content {
	font-style: italic;
	position: relative;
	left: 40%;
}

@media (max-width: 500px) {
	.container2 {
		width: 100%;
		padding: 5vh;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.container2-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		height: fit-content;
		border-radius: 100px;
		color: #ffa319;
		line-height: 1.5;
	}

	.container2-cards {
		font-size: 18px;
		padding: 10% 5%;
	}

	.bold {
		font-weight: 620;
	}

	.container2-content img {
		width: 80%;
		height: auto;
		border-radius: 100px;
	}

	.b-content {
		font-style: italic;
		margin-top: 20px;
		position: static;
	}
	.menuIcon {
		padding: 10px;
	}
}

@media (max-width: 1024px) {
	.container2 {
		width: 100%;
		padding: 5vh;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.container2-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 60%;
		height: fit-content;
		border-radius: 100px;
		color: #ffa319;
		line-height: 1.5;
	}

	.container2-cards {
		font-size: 18px;
		padding: 10% 5%;
	}

	.bold {
		font-weight: 620;
	}

	.container2-content img {
		width: 50%;
		height: auto;
		border-radius: 100px;
	}

	.b-content {
		font-style: italic;
		margin-top: 20px;
		position: static;
	}
}

.container5 {
	background-color: #e5e5e5;
	text-align: center;
	display: flex;
	flex-direction: column;
	padding: 30px;
}

.container5-row-1 {
	background-color: linear-gradient(
		180deg,
		#000000 7.74%,
		rgba(0, 0, 0, 0.7) 51.83%,
		#000000 93.39%
	);
	padding: 20px;
}

.but-5 {
	display: flex;
	justify-content: space-between;
	padding: 10px 100px 30px 100px;
}

.container5-row-1-1 .but-5 {
	display: flex;
	justify-content: center;
	padding: 10px 100px 30px 100px;
}

.but-5 button {
	background: #f9efdf;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 50px;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: center;
	width: 20%;
	font-size: 16px;
	border: none;
	padding: 15px 5px 3px 5px;
	/* line-height: 10px; */
}

.but-5 img {
	width: 50px;
	height: 50px;
	margin: 10px;
}

.container5-row-1-1 button {
	background: #f9efdf;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 50px;
	display: flex;
	align-items: center;
	text-align: left;
	width: 18%;
	padding: 15px 15px 15px 25px;
	line-height: 10px;
	border: none;
	margin: 0 45px;
}

.container5-row-1-2 button {
	background: #f9efdf;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 50px;
	display: flex;
	align-items: center;
	text-align: left;
	width: 35%;
	margin-left: 400px;
	padding: 18px 18px 18px 25px;
	line-height: 10px;
	border: none;
}

.container5-row-1-1 button img {
	height: 50px;
	width: 50px;
	margin: 10px;
}

.container5-row-2 {
	background: #f1fffe;
	border: 1px solid #000000;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 30px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 80%;
	min-height: 150px;
	margin-left: 120px;
	border: none;
	padding: 5px 5px 5px 5px;
}

.row-5 {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.row-5-1 {
	margin: 15px 25px 10px 15px;
	text-align: left;
	font-size: 16px;
}

.container5-row-2 img {
	height: 70px;
}

@media (max-width: 500px) {
	.container5 {
		background-color: #e5e5e5;
		text-align: center;
		display: flex;
		flex-direction: column;
		padding: 30px;
	}

	.container5 h2 {
		font-size: large;
	}

	.container5-row-1 {
		background-color: linear-gradient(
			180deg,
			#000000 7.74%,
			rgba(0, 0, 0, 0.7) 51.83%,
			#000000 93.39%
		);
		padding: 20px;
	}

	.but-5 {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		padding: 10px;
	}

	.but-5 button {
		background: #f9efdf;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 50px;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		font-size: 14px;
		border: none;
		padding: 15px 5px 3px 5px;
		margin: 5% 0;
	}

	.but-5 img {
		width: 30px;
		height: 30px;
		margin: 10px;
	}

	.container5-row-1-1 .but-5 {
		flex-direction: column;
		align-items: center;
		padding: 10px;
	}

	.container5-row-1-1 button {
		background: #f9efdf;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 50px;
		display: flex;
		align-items: center;
		text-align: left;
		width: 70%;
		padding: 15px 15px 15px 25px;
		line-height: 10px;
		border: none;
		margin: 10px;
	}

	.container5-row-1-1 button img {
		height: 30px;
		width: 30px;
		margin: 10px;
	}

	.container5-row-1-2 button {
		background: #f9efdf;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 50px;
		display: flex;
		align-items: center;
		text-align: left;
		width: 95%;
		margin: 10px;
		padding: 15px 18px 15px 25px;
		line-height: 1;
		border: none;
	}

	.container5-row-1-2 button img {
		height: 30px;
		width: 30px;
		margin: 10px;
	}

	.container5-row-2 {
		background: #f1fffe;
		border: 1px solid #000000;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 30px;
		display: flex;
		align-items: center;
		justify-content: space-around;
		width: 80%;
		min-height: 150px;
		margin: 10px;
		border: none;
		padding: 5px;
	}

	.container5-row-2 img {
		height: 70px;
	}

	.row-5 {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.row-5-1 {
		margin: 15px 25px 10px 15px;
		text-align: left;
		font-size: 16px;
	}
}

@media (max-width: 1024px) {
	.container5 {
		background-color: #e5e5e5;
		text-align: center;
		display: flex;
		flex-direction: column;
	}

	.container5 h2 {
		font-size: large;
	}

	.container5-row-1 {
		width: 100%;
	}

	.but-5 {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		padding: 10px;
	}

	.but-5 button {
		background: #f9efdf;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 50px;
		display: flex;
		align-items: center;
		width: 100%;
		font-size: 14px;
		border: none;
		padding: 15px 5px 3px 5px;
		margin: 5% 0;
	}

	.but-5 img {
		width: 30px;
		height: 30px;
		margin: 10px;
	}

	.container5-row-1-1 .but-5 {
		flex-direction: column;
		align-items: center;
		padding: 10px;
	}

	.container5-row-1-1 button {
		background: #f9efdf;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 50px;
		display: flex;
		align-items: center;
		text-align: left;
		width: 70%;
		padding: 15px 15px 15px 25px;
		line-height: 10px;
		border: none;
		margin: 10px;
	}

	.container5-row-1-1 button img {
		height: 30px;
		width: 30px;
		margin: 10px;
	}

	.container5-row-1-2 button {
		background: #f9efdf;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 70%;
		padding: 15px 18px 15px 50px;
		margin-left: 15vw;
		line-height: 1;
		border: none;
	}

	.container5-row-1-2 button img {
		height: 30px;
		width: 30px;
		margin: 10px;
	}

	.container5-row-2 {
		background: #f1fffe;
		border: 1px solid #000000;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 30px;
		display: flex;
		align-items: center;
		justify-content: space-around;
		width: 80%;
		min-height: 150px;
		margin: 10px;
		border: none;
		padding: 5px;
	}

	.container5-row-2 img {
		height: 70px;
	}

	.row-5 {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.row-5-1 {
		margin: 15px 25px 10px 15px;
		text-align: left;
		font-size: 16px;
	}
}

.container6 {
	background-image: url('./images/unsplash_PxypFiQMkIk.png') !important;
	text-align: center;
	padding: 5vh;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.card-6 {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.7) 52.78%, #000000 100%);
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 50px;
	padding: 30px 50px 0px 50px;
	margin: 30px 50px 0px 50px;
}

.card-6-content-1 {
	color: white;
	padding: 0px 15px 30px 15px;
}

.card-6-content-2 {
	color: #ffa319;
	padding: 0px 15px 20px 15px;
}

.card-6-content-3 {
	color: #ffa319;
	font-size: 10px;
	display: flex;
	align-self: flex-end;
}

.card-6-row {
	display: flex;
}

.card-6-content-2-col-1 img {
	width: 100px;
}

.card-6-content-2-col-1 p {
	font-size: 12px;
	width: 145px;
}

.card-6-content-2-col {
	display: flex;
	justify-content: space-around;
	color: #ffa319;
}

.card-6-content-2-col-2 {
	position: relative;
}

.card-6-content-2-col-2 img {
	position: absolute;
	bottom: 45px;
	left: 20px;
	width: 50px;
}

.card-6-content-2-col-2 p {
	font-size: 13px;
	padding-top: 35px;
}

@media (max-width: 500px) {
	.container6 {
		background-image: url('./images/unsplash_PxypFiQMkIk.png') !important;
		background-repeat: no-repeat;
		background-size: cover;
		text-align: center;
		padding: 5vh;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.card-6 {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.7) 52.78%, #000000 100%);
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 50px;
		padding: 20px;
		margin: 20px;
	}

	.card-6-content-1 {
		color: white;
		padding: 0px 10px 20px 10px;
	}

	.card-6-content-2 {
		color: #ffa319;
		padding: 0px 10px 20px 10px;
	}

	.card-6-content-3 {
		color: #ffa319;
		font-size: 10px;
	}

	.card-6-row {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	.card-6-content-2-col-1 img {
		width: 50px;
	}

	.card-6-content-2-col-1 p {
		font-size: 10px;
		width: 100% !important;
	}

	.card-6-content-2-col {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		color: #ffa319;
		margin-top: 10px;
	}

	.card-6-content-2-col-2 img {
		width: 20px;
		position: relative;
		bottom: auto;
		left: auto;
	}

	.card-6-content-2-col-2 p {
		font-size: 12px;
		padding-top: 10px;
		text-align: center;
	}
}

@media (min-width: 500px) and (max-width: 1024px) {
	.container6 {
		background-image: url('./images/unsplash_PxypFiQMkIk.png') !important;
		background-repeat: no-repeat;
		background-size: cover;
		text-align: center;
		padding: 5vh;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.card-6 {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.7) 52.78%, #000000 100%);
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 50px;
		padding: 20px;
		margin: 20px;
	}

	.card-6-content-1 {
		color: white;
		padding: 0px 10px 20px 10px;
	}

	.card-6-content-2 {
		color: #ffa319;
		padding: 0px 10px 20px 10px;
	}

	.card-6-content-3 {
		color: #ffa319;
		font-size: 10px;
	}

	.card-6-row {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	.card-6-content-2-col-1 img {
		width: 50px;
	}

	.card-6-content-2-col-1 p {
		font-size: 10px;
		width: 100% !important;
	}

	.card-6-content-2-col {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		color: #ffa319;
		margin-top: 10px;
	}

	.card-6-content-2-col-2 img {
		width: 20px;
		position: relative;
		bottom: auto;
		left: auto;
	}

	.card-6-content-2-col-2 p {
		font-size: 12px;
		padding-top: 10px;
		text-align: center;
	}
}

.container-t {
	width: 100%;
	height: 100vh;
	padding: 1%;
	background-color: #e5e5e5;
}

.container-t h2 {
	text-align: center;
	padding: 1%;
	text-shadow: 5px 5px 5px rgba(255, 163, 25, 0.6);
}

.card-t {
	display: flex;
	flex-direction: row-reverse;
	perspective: 800px;
	height: 80vh;
	overflow-x: hidden;
	overflow-y: scroll;
}

.column-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	transform: rotatez(-7deg) !important;
}

.column {
	width: 25vw;
	padding: 2px;
	perspective: 1000px;
}

.column img {
	height: 50vh;
	width: 50vh;
	animation: scrollAnimation 25s linear infinite;
	animation-play-state: running;
	z-index: 1;
	transition: opacity 0.3s;
	position: relative;
}

.column:hover img {
	transform: scale(1.2);
	position: static;
	z-index: 99;
	animation-play-state: paused;
}

@keyframes scrollAnimation {
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(-100%);
	}
}

.container-tv {
	width: 100%;
	height: 75vh;
	padding: 2%;
	background-color: #e5e5e5;
}

.ant-card-body {
	display: none;
}
