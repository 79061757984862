.container-tournament {
	background-color: #f3e9de;
	padding: 20px;
	margin-top: 20px;
}

.container-tournament h1 {
	text-align: center;
	padding-top: 30px;
	margin-top: 20px;
	font-size: 45px;
}

.content-tournament {
	/* display: flex; */
	margin-top: 50px;
}

.tournament-container {
	margin-top: 10px;
	padding-top: 10px;
}

.reduce-gap {
	margin-bottom: 1px; /* Adjust this value as needed */
  }
  

.image-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	margin-top: 10px;
	max-width: 100%; /* Ensures image doesn't exceed the container's width */
    height: auto; /* Keeps the image aspect ratio intact */
}
@media (min-width: 500px) {
	.image-container img {
		width: 20%;
	}
}
@media (max-width: 500px) {
	.container-tournament h1 {
		font-size: 32px;
		margin: 0px;
	}
}
