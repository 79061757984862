.acontainer1 {
    padding-top: 10%;
    background-color: black;
    position: relative;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.acontainer1 .acontent {
    position: absolute;
    width: 70%;
    padding-left: 8%;
}

.acontainer1 .acontent h1 {
    color: #FFA319;
    margin: 1% 0%;
}

.acontainer1 .acontent p {
    color: #FFFFFF;
    margin: 5% 0%;
}

.acontainer1 .acontent hr {
    border: 1px solid #FFFFFF;
    width: 50%;
    margin: 5% 0% 7%;
}

.acontainer1 img {
    width: 30%;
    position: relative;
    left: 60%;
    top: 10vh;
}

@media (max-width: 768px) {
    .acontainer1 {
      height: 50vh !important;
    }

    .acontent h1{
        font-size: medium;
    }

    .acontainer1 img {
        width: 40%;
        position: relative;
        left: 60%;
        top: 12vh;
    }
  }

.acontainer2{
    position: relative;
    padding: 5%;
    height: 95vh;
    background-image: url('../images/unsplash_PxypFiQMkIk.png');
}
.acontainer2 .card {
    position: relative;
    left: 40%;
    width: 45%;
    border-radius: 50px;
    font-size: 14px;
    color: #FFA319;
    background-color: #000000;
    display: flex;
    align-items: center;
    padding: 4% 3%;    
}

.acontainer2 .card h3{
    padding: 2%;
}

.acontainer2 .card p{
    padding: 2%;
}

.acontainer2 img{
    position: relative;
    width: 28%;
    left: 20%;
    bottom: 10vh;
}


@media (max-width: 768px) {
    .acontainer2 {
      height: 80vh;
    }

    .acontainer2 .card{
        position: relative;
        margin-top: 10%;
        left: 25%;
        width: 70%;
        height: 70%;
        display: flex;
        justify-content: center;
    }

    .acontainer2 img{
        position: relative;
        width: 30%;
        left: 10%;
        bottom: 3vh;
    }
  }

.acontainer3{
    position: relative;
    padding: 5%;
    height: 95vh;
    background-color: #E5E5E5;;
}
.acontainer3 .card {
    position: relative;
    left: 15%;
    width: 45%;
    border-radius: 50px;
    font-size: 14px;
    color: #FFA319;
    background-color: #000000;
    display: flex;
    align-items: center;
    padding: 4% 3%;    
}

.acontainer3 .card h3{
    padding: 2%;
}

.acontainer3 .card p{
    padding: 2%;
}

.acontainer3 img{
    position: relative;
    width: 28%;
    left: 50%;
    bottom: 10vh;
}

@media (max-width: 768px) {
    .acontainer3 {
      height: 80vh;
    }

    .acontainer3 .card{
        position: relative;
        margin-top: 10%;
        left: 15%;
        width: 70%;
        height: 65%;
        display: flex;
        justify-content: center;
    }

    .acontainer3 img{
        position: relative;
        width: 40%;
        left: 55%;
        bottom: 5vh;
    }
  }

.acontainer4{
    position: relative;
    padding: 5%;
    height: 95vh;
    background-image: url('../images/unsplash_PxypFiQMkIk.png');
}
.acontainer4 .card {
    position: relative;
    left: 40%;
    width: 45%;
    border-radius: 50px;
    font-size: 14px;
    color: #FFA319;
    background-color: #000000;
    display: flex;
    align-items: center;
    padding: 4% 3%;    
}

.acontainer4 .card h3{
    padding: 2%;
}

.acontainer4 .card p{
    padding: 2%;
}

.acontainer4 img{
    position: relative;
    width: 28%;
    left: 20%;
    bottom: 10vh;
}

@media (max-width: 768px) {
    .acontainer4 {
      height: 80vh;
    }

    .acontainer4 .card{
        position: relative;
        margin-top: 10%;
        left: 25%;
        width: 70%;
        height: 50%;
        display: flex;
        justify-content: center;
    }

    .acontainer4 img{
        position: relative;
        width: 30%;
        left: 10%;
        bottom: 3vh;
    }
  }