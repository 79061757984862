.comparisionTable {
	width: 50%;
	border-collapse: collapse;
}

.comparisionTable,
.comparisionTable th,
.comparisionTable td {
	border: 2px solid black;
}

.comparisionTable th,
.comparisionTable td {
	padding: 10px;
	text-align: center;
}

.comparisionTable th {
	background-color: black; /* Change background color to black */
	color: white; /* Change text color to white */
	text-align: center;
}
