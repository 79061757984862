.hcontainer2 {
	height: 110vh;
	background-color: #f3e9de;
	padding: 2%;
	position: relative;
}

.hcontainer2 h2 {
	text-align: center;
	line-height: 2;
}

.hcontainer2 .head {
	font-style: italic;
	text-align: right;
	position: absolute;
	right: 26.5%;
}

.hcontainer2 .content {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 20px;
	font-weight: 700;
}

.hcontainer2 .hcard1 {
	position: absolute;
	left: 20%;
	top: 75%;
	width: 15%;
	height: 11%;
	padding: 1% 0.5%;
	line-height: 0.5;
	text-align: center;
	background: #ffa319;
	border: 4px solid #000000;
	box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25);
	border-radius: 9.6px;
}

.hcontainer2 .hcard2 {
	position: absolute;
	left: 33%;
	top: 62%;
	width: 15%;
	height: 11%;
	padding: 1% 0.5%;
	line-height: 0.5;
	text-align: center;
	background: #ffa319;
	border: 4px solid #000000;
	box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25);
	border-radius: 9.6px;
}

.hcontainer2 .hcard3 {
	position: absolute;
	left: 45%;
	top: 48%;
	width: 15%;
	height: 11%;
	padding: 1% 0.5%;
	line-height: 0.5;
	text-align: center;
	background: #ffa319;
	border: 4px solid #000000;
	box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25);
	border-radius: 9.6px;
}

.hcontainer2 .hcard4 {
	position: absolute;
	left: 57%;
	top: 35%;
	width: 15%;
	height: 11%;
	padding: 1% 0.5%;
	line-height: 0.5;
	text-align: center;
	background: #ffa319;
	border: 4px solid #000000;
	box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25);
	border-radius: 9.6px;
	z-index: 1;
}

.hcontainer2 .hcard5 {
	position: absolute;
	left: 68%;
	top: 22%;
	width: 15%;
	height: 11%;
	padding: 1% 0.5%;
	line-height: 0.5;
	text-align: center;
	background: #ffa319;
	border: 4px solid #000000;
	box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25);
	border-radius: 9.6px;
	z-index: 1;
}

.hcontainer2 .content .htable table {
	width: 70%;
	left: 14%;
	bottom: 1.5%;
	background: #c2c3c5;
	border-radius: 10px;
	border: 2px solid #ff2e3b;
	position: absolute;
	z-index: 1;
}

.hcontainer2 .htable .htcontent {
	line-height: 1;
	padding: 2% 5%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-left: 1%;
	border-left: 2px solid #ff2e3b;
}

.hcontainer2 .htable .ht1content p {
	margin-left: 5%;
	text-align: center;
}

.hcontainer2 .htable .ht1content p {
	margin-left: 5%;
	text-align: center;
}

.hcontainer2 .htable .ht1content {
	line-height: 1;
	padding: 0.5%;
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	padding-left: 7%;
	margin-left: 1%;
}

.htable .htcontent th {
	background-color: pink;
	color: white;
}

.hcontainer2 .img1 img {
	width: 32%;
}

.hcontainer2 .img2 img {
	position: absolute;
	bottom: 10%;
	right: 0%;
	width: 32%;
}

.home-n-p {
	text-align: left;
	position: relative;
	font-size: 22px;
	border-radius: 12px;
	border: 1px solid #f3e9de;
	padding: 15px;
}
.tournament {
	margin-bottom: 5rem;
	text-align: center;
}
.annual {
	margin-top: 5rem;
	margin-bottom: 2rem;
	text-align: center;
}
.home-n-l,
a {
	color: #ffa319 !important;
	text-decoration: none !important;
	cursor: pointer;
	text-align: left;
}

@media (max-width: 1000px) {
	.hcontainer2 {
		height: auto;
		background-color: #f3e9de;
		padding: 2%;
		position: relative;
		width: 100% !important;
	}

	.hcontainer2 h2 {
		text-align: center;
		line-height: 2;
	}

	.hcontainer2 .head {
		font-style: italic;
		text-align: right;
		margin: 0 auto;
	}

	.hcontainer2 .content {
		padding-top: 10%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-weight: 700;
	}

	.hcontainer2 .hcard1,
	.hcontainer2 .hcard2,
	.hcontainer2 .hcard3,
	.hcontainer2 .hcard4,
	.hcontainer2 .hcard5 {
		position: static;
		width: 80%;
		height: auto;
		padding: 2% 0.5%;
		line-height: 1;
		text-align: center;
		background: #ffa319;
		border: 4px solid #000000;
		box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25);
		border-radius: 9.6px;
		margin-bottom: 20px;
	}

	.hcontainer2 .content .htable table {
		position: relative;
		width: 98%;
		left: 0;
		right: 0;
		margin: 0 auto;
		background: #c2c3c5;
		border-radius: 10px;
		border: 2px solid #ff2e3b;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: xx-small;
	}

	.hcontainer2 .htable .htcontent,
	.hcontainer2 .htable .ht1content {
		line-height: 2;
		text-align: center;
		border-left: none;
	}

	.hcontainer2 .htable .htcontent th {
		background-color: pink;
	}

	.hcontainer2 .img1 img {
		display: none;
	}

	.hcontainer2 .img2 img {
		display: none;
	}
}

@media (max-width: 375px) {
	.hcontainer2 {
		height: auto;
		background-color: #f3e9de;
		padding: 2%;
		position: relative;
		width: 100% !important;
	}

	.hcontainer2 h2 {
		text-align: center;
		line-height: 2;
	}

	.hcontainer2 .head {
		font-style: italic;
		text-align: right;
		margin: 0 auto;
	}

	.hcontainer2 .content {
		padding-top: 30%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-weight: 700;
	}

	.hcontainer2 .hcard1,
	.hcontainer2 .hcard2,
	.hcontainer2 .hcard3,
	.hcontainer2 .hcard4,
	.hcontainer2 .hcard5 {
		position: static;
		width: 80%;
		height: auto;
		padding: 2% 0.5%;
		line-height: 1;
		text-align: center;
		background: #ffa319;
		border: 4px solid #000000;
		box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25);
		border-radius: 9.6px;
		margin-bottom: 20px;
	}

	.hcontainer2 .content .htable table {
		position: relative;
		width: 98%;
		left: 0;
		right: 0;
		margin: 0 auto;
		background: #c2c3c5;
		border-radius: 10px;
		border: 2px solid #ff2e3b;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: xx-small;
	}

	.hcontainer2 .htable .htcontent,
	.hcontainer2 .htable .ht1content {
		line-height: 2;
		text-align: center;
		border-left: none;
	}

	.hcontainer2 .htable .htcontent th {
		background-color: pink;
	}

	.hcontainer2 .img1 img {
		display: none;
	}

	.hcontainer2 .img2 img {
		display: none;
	}
}

.hcontainer3 {
	background-image: url('../images/andrej-lisakov-g3z-CgUiPtg-unsplash 1.png');
	height: 100vh;
	padding: 2%;
	position: relative;
}

.hcontainer3 h2 {
	text-align: center;
	line-height: 2;
	color: #ffa319;
}

.hcontainer3 .hcard1 {
	background-image: url('../images/Group 82.png');
	height: 25vh;
	width: 35%;
	position: absolute;
	top: 18%;
	left: 32%;
	text-align: center;
	padding: 4%;
	line-height: 2;
}

.hcontainer3 .hcard2 {
	background-image: url('../images/Group 82.png');
	height: 25vh;
	width: 47.1%;
	position: absolute;
	top: 41%;
	left: 25%;

	text-align: center;
	padding: 4%;
	line-height: 2;
	/* border: 5px solid #FFFFFF; */
}

.hcontainer3 .hcard3 {
	background-image: url('../images/Group 79.png');
	height: 25vh;
	width: 67%;
	position: absolute;
	top: 64%;
	left: 15%;

	text-align: center;
	padding: 4%;
	line-height: 2;
	/* border: 5px solid #FFFFFF; */
}

@media (max-width: 375px) {
	.hcontainer3 {
		height: fit-content;
	}

	.hcontainer3 h2 {
		text-align: center;
		color: #ffa319;
	}

	.hcontainer3 .hcard1 {
		background-image: url('../images/Group 82.png');
		height: auto;
		width: 90%;
		position: static;
		margin: 10px auto;
		text-align: center;
		padding: 4%;
		line-height: 2;
	}

	.hcontainer3 .hcard2 {
		background-image: url('../images/Group 82.png');
		height: auto;
		width: 90%;
		position: static;
		margin: 10px auto;
		text-align: center;
		padding: 4%;
		line-height: 2;
	}

	.hcontainer3 .hcard3 {
		background-image: url('../images/Group 79.png');
		height: auto;
		width: 90%;
		position: static;
		margin: 10px auto;
		text-align: center;
		padding: 4%;
		line-height: 2;
	}
}
