.containerC{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10% 5%;
}

.contentC{
    width: 30%;
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    background-color: white;
    margin: 5%;
    padding: 0 5%;
}

.navButton{
    width: 40%;
    display: flex;
    justify-content: space-between;
}

.navButton button {
    width: 150%;
    padding: 4%;
}

